import React from "react";
import Field from "./field.js";

const Email = (props) => {
  const validationFunction = (value) => {
    if (value.trim() === "") {
      return false;
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //see https://stackoverflow.com/a/9204568/8086209
    return regex.test(value);
  };
  return (<Field {...props} validationFunction={ validationFunction } />);
};

export default Email;
